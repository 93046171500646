import { Box, Drawer, IconButton } from '@mui/material'
import React, { useState }  from 'react'
import { Link } from 'react-router-dom' 
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';
const Menus = () => {
     
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
      };


      const [activeLink, setActiveLink] = useState('home'); // State to keep track of active link

      // Function to handle click event on navbar links
      const handleNavLinkClick = (link) => {
        setActiveLink(link); // Update active link state
      };
  

  return (
    <Box width='100%' textAlign='end'>
        <Drawer open={open} onClose={toggleDrawer(false)} sx={{backgroundColor:'#1f1d1e85'}}>
            <Box onClick={toggleDrawer(false)} position={'relative'} height={'100%'}>
                <ul className="UnListBoxMenus">
                    <li>
                        <Link className={activeLink === 'home' ? 'active' : ''} onClick={() => handleNavLinkClick('home')} to='/'>Home</Link>
                    </li>
                    <li>
                        <Link className={activeLink === 'buy-sell' ? 'active' : ''} onClick={() => handleNavLinkClick('buy-sell')} to='/buy-sell'>Buy-Sell</Link>
                    </li>
                    <li>
                        <Link className={activeLink === 'tokenomics' ? 'active' : ''} onClick={() => handleNavLinkClick('tokenomics')} to='/tokenomics'>Tokenomics</Link>
                    </li>
                    <li>
                        <Link className={activeLink === 'pledge' ? 'active' : ''} onClick={() => handleNavLinkClick('pledge')} to='/pledge'>Pledge</Link>
                    </li>
                    <li>
                        <Link className={activeLink === 'advocate-dashboard' ? 'active' : ''} onClick={() => handleNavLinkClick('advocate-dashboard')} to='/advocate-dashboard'>Advocate</Link>
                    </li>
                    <li>
                        <Link className={activeLink === '' ? '' : ''} onClick={() => handleNavLinkClick('')} to='/'>Upcoming</Link>
                    </li>
                </ul>
                <Box position={'absolute'} right='10px' top='10px'>
                    <IconButton onClick={toggleDrawer(false)}>
                        <CloseIcon sx={{ color: 'var(--colorWhite)',fontSize: 30 }} />
                    </IconButton>
                </Box>
            </Box>
        </Drawer>
        <Box marginLeft='auto'>
            <IconButton onClick={toggleDrawer(true)}>
                <MenuRoundedIcon sx={{ color: 'var(--colorWhite)',fontSize: 40 }} />
            </IconButton>
      </Box>
    </Box>
  )
}

export default Menus