import { Box, Container, Divider, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <Box bgcolor={'var(--bolorRedPrimary)'} paddingY={'3rem'} >
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item md="12" sm='12'>
                    <Box>
                        <Box display='flex' alignItems='center' justifyContent='space-between' >
                            <Box>
                                <Typography variant='h5' fontWeight='500' color={'var(--colorWhite)'}>Heist Coin</Typography>
                            </Box>
                            <Box>
                                <IconButton color='var(--colorWhite)'>
                                    <YouTubeIcon sx={{color:'var(--colorWhite)'}} />
                                </IconButton>
                                <IconButton color='var(--colorWhite)'>
                                    <FacebookIcon sx={{color:'var(--colorWhite)'}} />
                                </IconButton>
                                <IconButton color='var(--colorWhite)'>
                                    <TwitterIcon sx={{color:'var(--colorWhite)'}} />
                                </IconButton>
                                <IconButton color='var(--colorWhite)'>
                                    <InstagramIcon sx={{color:'var(--colorWhite)'}} />
                                </IconButton>
                                <IconButton color='var(--colorWhite)'>
                                    <LinkedInIcon sx={{color:'var(--colorWhite)'}} />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box paddingY={'3rem'}>
                            <Divider sx={{borderColor:'#C1C7CD'}}/>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='space-between' >
                            <Box>
                                <Typography variant='body' fontWeight='400' color={'var(--colorWhite)'}>Heist coin @ 2024. All rights reserved.</Typography>
                            </Box>
                            <Box display='flex' alignItems='center' gap='15px'>
                                <Link to='/' className='footerMenu' >Tokenomics</Link>
                                <Link to='/' className='footerMenu' >Pledge</Link>
                                <Link to='/' className='footerMenu' >Advocate</Link>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Box>
  )
}

export default Footer