import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'

const TradingGoesLive = () => {
  return (
    <Box bgcolor={'var(--bolorYellowHover)'} paddingY='1rem' >
        <Container maxWidth={'lg'}>
            <Grid container>
                <Grid item md={'12'}>
                    <Box sx={{display:{md:'flex', sx:'block'}, textAlign:'center', alignItems:'center', justifyContent:'center', gap:'15px'}}>
                        <Box>
                            <Typography textAlign={'center'} variant='h6' fontWeight={'500'}>Trading goes live in</Typography>
                        </Box>
                        <Box>
                            <Typography textAlign={'center'} variant='h5' fontWeight={'500'}>60 days : 18 hours : 20 minutes : 45 seconds</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Box>
  )
}

export default TradingGoesLive