import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import settingImg from '../../../assets/img/HeistProduced.png'
import HeistBurnt from '../../../assets/img/HeistBurnt.png'
import HrsBurn from '../../../assets/img/HrsBurn.png'
import CirculatingSupply from '../../../assets/img/CirculatingSupply.png'


const NumbersThatMatter = () => {
  return (
    <Box paddingY={'3rem'} bgcolor={'#2F2D2E'}>
     <Container maxWidth="lg">
        <Grid conatiner spacing={2}>
            <Grid item md="12">
                <Box marginBottom={'3rem'}>
                    <Box paddingBottom={'2rem'}>
                        <Typography variant='h3' textAlign={'center'} color={'var(--colorWhite)'} fontWeight={'600'} gutterBottom>Numbers That Matter</Typography>
                    </Box>
                    <Box textAlign={'center'}>
                        <Typography variant='body' color={'var(--colorWhite)'}>
                            Rhoncus morbi et augue nec, in id ullamcorper at sit. Condimentum sit nunc in eros scelerisque sed. Commodo in viverra nunc, ullamcorper ut. Non, amet, aliquet scelerisque nullam sagittis, pulvinar. Fermentum scelerisque sit consectetur hac mi. Mollis leo eleifend ultricies purus iaculis.
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
        <Grid container spacing={2}> 
            <Grid item md="3" sm='6' xs="12">
                <Box bgcolor={'var(--bolorRedPrimary)'} sx={{paddingX:{md:'2rem'},paddingY:'10px', borderRadius:'8px' }}>
                    <Box textAlign={'center'}>
                        <img src={settingImg} alt='' width={'45px'} />
                    </Box>
                    <Box textAlign='center'>
                        <Typography textAlign={'center'} variant='h4' fontWeight={'600'} color={'var(--colorWhite)'}>250+</Typography>
                        <Typography textAlign={'center'} variant='body' fontWeight={'500'} color={'var(--colorWhite)'}>Heist Produced</Typography>
                    </Box>
                </Box>
            </Grid> 
            <Grid item md="3" sm='6' xs="12">
                <Box bgcolor={'var(--bolorRedPrimary)'} sx={{paddingX:{md:'2rem'},paddingY:'10px', borderRadius:'8px' }}>
                    <Box textAlign={'center'}>
                        <img src={HeistBurnt} alt='' width={'45px'} />
                    </Box>
                    <Box textAlign='center'>
                        <Typography textAlign={'center'} variant='h4' fontWeight={'600'} color={'var(--colorWhite)'}>600+</Typography>
                        <Typography textAlign={'center'} variant='body' fontWeight={'500'} color={'var(--colorWhite)'}>Heist Burnt</Typography>
                    </Box>
                </Box>
            </Grid> 
            <Grid item md="3" sm='6' xs="12">
                <Box bgcolor={'var(--bolorRedPrimary)'} sx={{paddingX:{md:'2rem'},paddingY:'10px', borderRadius:'8px' }}>
                    <Box textAlign={'center'}>
                        <img src={HrsBurn} alt='' width={'45px'} />
                    </Box>
                    <Box textAlign='center'>
                        <Typography textAlign={'center'} variant='h4' fontWeight={'600'} color={'var(--colorWhite)'}>1.8K+</Typography>
                        <Typography textAlign={'center'} variant='body' fontWeight={'500'} color={'var(--colorWhite)'}>Past 24 Hrs Burn</Typography>
                    </Box>
                </Box>
            </Grid> 
            <Grid item md="3" sm='6' xs="12">
                <Box bgcolor={'var(--bolorRedPrimary)'} sx={{paddingX:{md:'2rem'},paddingY:'10px', borderRadius:'8px' }}>
                    <Box textAlign={'center'}>
                        <img src={CirculatingSupply} alt='' width={'45'} />
                    </Box>
                    <Box textAlign='center'>
                        <Typography textAlign={'center'} variant='h4' fontWeight={'600'} color={'var(--colorWhite)'}>11K+</Typography>
                        <Typography textAlign={'center'} variant='body' fontWeight={'500'} color={'var(--colorWhite)'}>Circulating Supply</Typography>
                    </Box>
                </Box>
            </Grid> 
        </Grid>
     </Container>
    </Box>
  )
}

export default NumbersThatMatter