import { Box, Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import FAQs from '../faqs/FAQs';
// import './Pledge.css' 

const Tokenomics = () => {

    const [selectTab, setSelectTab] = useState(0);


  return (
    <Box bgcolor={'var(--blackDarkColor)'} paddingY={'4rem'}>
        <Box>
            <Container maxWidth={'lg'}>
                <Grid container spacing={2}>
                    <Grid item lg='10' md='10' sm='12' xs='12' margin={'auto'}>
                        <Box className='dashboardContainer' sx={{paddingX:{md:'4rem', sm:'10px', xs:'10px'},paddingY:{md:'3rem', sm:'20px', xs:'16px'}}} borderRadius={'8px'}>
                            <Box>
                                <Typography variant='h4' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'} gutterBottom>Tokenomics</Typography>
                            </Box>
                            <Box sx={{ display: { md: "flex", sx: "flex", xs: "flex" }, alignItems: "center", justifyContent: "space-between", marginY: "1.3rem", paddingX:{md:'0px', sx:'10px', xs:'10px'} }}>
                                <button
                                    className="SolHeistTabs active"
                                    onClick={() => {
                                        setSelectTab(0);
                                    }}
                                    style={{
                                        borderBottomLeftRadius: "8px",
                                        borderTopLeftRadius: "8px",
                                        color: selectTab === 1 ? "var(--colorWhite)" : "var(--colorWhite)",
                                        backgroundColor: selectTab === 0 ? "var(--bolorRedPrimary)" : "var(--blackDarkColor)" }}
                                >
                                    SolHeist
                                </button>
                                <button
                                    className="SolHeistTabs"
                                    onClick={() => {
                                        setSelectTab(1);
                                    }}
                                    style={{
                                        borderBottomRightRadius: "8px",
                                    borderTopRightRadius: "8px",
                                    color: selectTab === 1 ? "var(--colorWhite)" : "var(--colorWhite)",
                                    backgroundColor: selectTab === 1 ? "var(--bolorRedPrimary)" : "var(--blackDarkColor)" }}
                                >
                                    SH-Pledge
                                </button>
                            </Box>;


                            {selectTab === 0 ?( 
                                <Box>
                                    <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} marginBottom={'2rem'} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                        <Box bgcolor={'#2F2D2E'} padding={'15px'}>
                                            <Typography variant='h5' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'}>SolHeist allocation</Typography>
                                        </Box> 
                                        <Box sx={{paddingX:{md:'2rem', sx:'10px', xs:'10px'}}}>
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Max supply</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>20,000,000,000 SolHeist</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Pledge bonus (10 years)</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>10,000,000,000 SolHeist</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Opening liquidity (SOL-SolHeist Raydium)</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>10,000,000,000 SolHeist</Typography>
                                            </Box>  
                                               
                                        </Box>
                                    </Box>
                                    <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} marginBottom={'2rem'} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                        <Box bgcolor={'#2F2D2E'} padding={'15px'}>
                                            <Typography variant='h5' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'}>Pledge bonus</Typography>
                                        </Box> 
                                        <Box sx={{paddingX:{md:'2rem', sx:'10px', xs:'10px'}}}>
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Daily</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>2,739,726 SolHeist</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Yearly</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>1,000,000,000 SolHeist</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Lifetime</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>10,000,000,000 SolHeist</Typography>
                                            </Box>  
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Bonus life cycle</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>10 years</Typography>
                                            </Box>  
                                               
                                        </Box>
                                    </Box>
                                    <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} marginBottom={'2rem'} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                        <Box bgcolor={'#2F2D2E'} padding={'15px'}>
                                            <Typography variant='h5' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'}>SolHeist production</Typography>
                                        </Box> 
                                        <Box sx={{paddingX:{md:'2rem', sx:'10px', xs:'10px'}}}>
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Pledged</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>50,000,000</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>SolHeist produced</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>300,000,000</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>SolHeist claimed</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>130,000,000</Typography>
                                            </Box>  
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>SolHeist unclaimed</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>170,000,000</Typography>
                                            </Box>  
                                        </Box>
                                    </Box>
                                    <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} marginBottom={'2rem'} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                        <Box bgcolor={'#2F2D2E'} padding={'15px'}>
                                            <Typography variant='h5' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'}>Production difficulty (365 days)</Typography>
                                        </Box> 
                                        <Box sx={{paddingX:{md:'2rem', sx:'10px', xs:'10px'}}}>
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>1 Pledge</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>0.0123 SolHeist</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>1 SolHeist</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>200 Pledge</Typography>
                                            </Box>  
                                        </Box>
                                    </Box>
                                    <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} marginBottom={'2rem'} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                        <Box bgcolor={'#2F2D2E'} padding={'15px'}>
                                            <Typography variant='h5' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'}>SolHeist holdings</Typography>
                                        </Box> 
                                        <Box sx={{paddingX:{md:'2rem', sx:'10px', xs:'10px'}}}>
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>SolHeist produced</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>10,300,000,000 SolHeist</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>SolHeist burnt</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--bolorRedPrimary)'}>-200,000,000 SolHeist</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Active circulating</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>10,100,000,000 SolHeist</Typography>
                                            </Box>  
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Sol-SolHeist pair</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>2,000,000,000 SolHeist</Typography>
                                            </Box>  
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>User’s Hold</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>8,100,000,000 SolHeist</Typography>
                                            </Box>  
                                        </Box>
                                    </Box> 
                                </Box>
                            ): null}
                            {selectTab === 1 ?(
                                <Box> 
                                    <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} marginBottom={'2rem'} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                        <Box bgcolor={'#2F2D2E'} padding={'15px'}>
                                            <Typography variant='h5' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'}>SH-Pledge allocation</Typography>
                                        </Box> 
                                        <Box sx={{paddingX:{md:'2rem', sx:'10px', xs:'10px'}}}>
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Max supply</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>250,000,000 SH-Pledge</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Team</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>25,000,000 SH-Pledge</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>For sale</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>225,000,000 SH-Pledge</Typography>
                                            </Box>  
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Pledge validity</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>10 years</Typography>
                                            </Box>  
                                        </Box>
                                    </Box>
                                    <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} marginBottom={'2rem'} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                        <Box bgcolor={'#2F2D2E'} padding={'15px'}>
                                            <Typography variant='h5' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'}>SH-Pledge sales</Typography>
                                        </Box> 
                                        <Box sx={{paddingX:{md:'2rem', sx:'10px', xs:'10px'}}}>
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>For sale</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>225,000,000 SH-Pledge</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Sold</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>200,000,000 SH-Pledge</Typography>
                                            </Box>  
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Unsold</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>25,000,000 SH-Pledge</Typography>
                                            </Box>  
                                        </Box>
                                    </Box>
                                    <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} marginBottom={'2rem'} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                        <Box bgcolor={'#2F2D2E'} padding={'15px'}>
                                            <Typography variant='h5' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'}>Sale phases</Typography>
                                        </Box> 
                                        <Box sx={{paddingX:{md:'2rem', sx:'10px', xs:'10px'}}}>
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Phase 1 - day 1 to 15</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>$1 = 2.00 SH-Pledge</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Phase 2 - day 16 to 30</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>$1 = 1.75 SH-Pledge</Typography>
                                            </Box> 
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Phase 3 - day 31 to 45</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>$1 = 1.50 SH-Pledge</Typography>
                                            </Box>  
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Phase 4 - day 46 to 60</Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>$1 = 1.25 SH-Pledge</Typography>
                                            </Box>  
                                            <Box paddingY={'10px'} className='SolDisplayFlex'>
                                                <Typography variant='body' color={'#AFAFAF'}>Phase 5 - day 61 to until sold </Typography>
                                                <Typography variant='body' fontWeight={'600'} color={'var(--colorWhite)'} textAlign={'end'}>$1 = 1.00 SH-Pledge</Typography>
                                            </Box>  
                                        </Box>
                                    </Box>
                                </Box>
                            ): null}
 
                             
                            <Box bgcolor={'var(--bolorRedPrimary)'} padding='12px' borderRadius={'8px'}>
                                <Typography textAlign={'center'} color={'var(--colorWhite)'}>Contract address</Typography>
                                <Typography textAlign={'center'} variant='body2' color={'var(--colorWhite)'}>0x1F1e263a37945047aF9C1695f2b9d108d956FBD6</Typography>
                            </Box> 
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        <Box>
            <FAQs />
        </Box>
    </Box>
  )
}

export default Tokenomics