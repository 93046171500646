import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import './Pledge.css'
import DragHandleIcon from '@mui/icons-material/DragHandle';
import BackHandIcon from '@mui/icons-material/BackHand';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FAQs from '../faqs/FAQs';

const Pledge = () => {
  return (
    <Box bgcolor={'var(--blackDarkColor)'} paddingY={'4rem'}>
        <Box>
            <Container maxWidth={'lg'}>
                <Grid container spacing={2}>
                    <Grid item lg='10' md='10' sm='12' xs='12' margin={'auto'}>
                        <Box className='dashboardContainer' sx={{paddingX:{md:'4rem', sm:'10px', xs:'10px'},paddingY:{md:'3rem', sm:'20px', xs:'16px'}}} borderRadius={'8px'}>
                            <Box>
                                <Typography variant='h4' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'} gutterBottom>Pre-Pledge dashboard</Typography>
                                <Box borderRadius='8px' padding='15px' bgcolor={'#2F2D2E'} marginY={'2rem'}>
                                    <Typography color={'#AFAFAF'}>Make a pledge and earn 40 SolHeist token over two years. Pledges will also collect 10% pledge supply of all future tokens launched on SolHeist platform</Typography>
                                </Box>
                            </Box>

                            <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'}  sx={{padding:{md:'2rem', sx:'10px', xs:'10px'}}} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                <Box marginBottom={'2rem'}>
                                    <Typography variant='h5' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'} gutterBottom>Buy SH-Pledge</Typography>
                                </Box>
                                <Box marginBottom={'3rem'}>
                                    <Box sx={{display:{md:'flex', sm:'block', xs:'block'}, alignItems:'center', gap:'15px', flexWrap:'wrap'}}>
                                        <Box className='shBoxIn' sx={{width:{md:'48%', sm:'100%', xs:'100%',},justifyContent:'center'}}>
                                            <Typography variant='h6' color={'var(--colorWhite)'}>1 USD </Typography>
                                            <DragHandleIcon sx={{color:'var(--colorWhite)'}} />
                                            <Typography variant='h6' fontWeight={'600'} color={'var(--colorWhite)'}>2 SH-Pledge</Typography>
                                        </Box>
                                        <Box className='shBoxIn' sx={{width:{md:'48%', sm:'100%', xs:'100%',},justifyContent:'center'}}>
                                            <Typography variant='h6' color={'var(--colorWhite)'}>1 USD </Typography>
                                            <DragHandleIcon sx={{color:'var(--colorWhite)'}} />
                                            <Typography variant='h6' fontWeight={'600'} color={'var(--colorWhite)'}>2 SH-Pledge</Typography>
                                        </Box>
                                        <Box className='shBoxIn' sx={{width:{md:'48%', sm:'100%', xs:'100%',},justifyContent:'space-between'}}>
                                            <Typography variant='h6' color={'var(--colorWhite)'}>Pledges left</Typography> 
                                            <Typography variant='h6' fontWeight={'600'} color={'var(--colorWhite)'}>60,000,000</Typography>
                                        </Box>
                                        <Box className='shBoxIn' sx={{width:{md:'48%', sm:'100%', xs:'100%',},justifyContent:'space-between'}}>
                                            <Typography variant='h6' color={'var(--colorWhite)'}>Max supply</Typography> 
                                            <Typography variant='h6' fontWeight={'600'} color={'var(--colorWhite)'}>100,000,000</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box sx={{display:{md:'flex', sm:'block', xs:'block'}, alignItems:'center', gap:'15px', flexWrap:'wrap'}}> 
                                        <Box sx={{width:{md:'48%', sm:'100%', xs:'100%',}}}>
                                            <Typography color={'var(--colorWhite)'}>I wish to pledge</Typography>
                                            <Box position='relative' marginY={'10px'}>
                                                <input placeholder='100' className='InputType' />
                                                <button className='inputbtns'>
                                                    USD
                                                </button>
                                            </Box>  
                                        </Box> 
                                        <Box sx={{width:{md:'48%', sm:'100%', xs:'100%',}}}>
                                            <Typography color={'var(--colorWhite)'}>You will receive</Typography>
                                            <Box position='relative' marginY={'10px'}>
                                                <input placeholder='200' className='InputType' />
                                                <button className='inputbtns'>
                                                    SH-Pledge
                                                </button>
                                            </Box>  
                                        </Box> 
                                        <Box sx={{width:{md:'48%', sm:'100%', xs:'100%',}}}>
                                            <Typography color={'var(--colorWhite)'}>Your Solana balance</Typography>
                                            <Box position='relative' marginY={'10px'}>
                                                <input placeholder='5.1234' className='InputType' />
                                                <button className='inputbtns'>
                                                    SOL
                                                </button>
                                            </Box>  
                                        </Box> 
                                        <Box sx={{width:{md:'48%', sm:'100%', xs:'100%',}}}>
                                            <Typography color={'var(--colorWhite)'}>You pay</Typography>
                                            <Box position='relative' marginY={'10px'}>
                                                <input placeholder='0.6713 ' className='InputType' />
                                                <button className='inputbtns'>
                                                    SOL 
                                                </button>
                                            </Box>  
                                        </Box> 
                                    </Box>
                                    <Box>
                                        <button className='MakepledgeBtn'> <BackHandIcon /> Make pledge</button>
                                    </Box>
                                    <Box sx={{display:{md:'flex', sx:'block', xs:'block'}, alignItems:'center', justifyContent:'space-between'}}>
                                        <Link to='/' className='LinksDownload'>Download Phantom wallet</Link>
                                        <Link to='/' className='LinksDownload'>Where can I buy Solana?</Link>
                                    </Box>
                                </Box>
                            </Box>
                                
                            <Box sx={{display:{md:'flex', sx:'flex', xs:'flex'}, alignItems:'center', justifyContent:'space-between', gap:'15px', marginY:'2rem'}}>
                                <button className='SolHeistoutline'>
                                     <ErrorOutlineIcon/>
                                    SolHeist 
                                    <ArrowForwardIcon/>
                                </button>
                                <button className='SolHeistoutline'>
                                     <ErrorOutlineIcon/> SH-Pledge 
                                    <ArrowForwardIcon/>
                                </button>
                            </Box>
                            
                            <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'}  sx={{padding:{md:'2rem', sx:'10px', xs:'10px'}}} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                <Box marginBottom={'2rem'}>
                                    <Typography variant='h5' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'} gutterBottom>Your SH-Pledge progress</Typography>
                                </Box>
                                <Divider sx={{borderColor:'#312727'}} />
                                <Box>
                                    <Box paddingY={'1rem'} sx={{display:{md:'flex', sm:'flex', xs:'flex'}, alignItems:'center',justifyContent:'space-between'}}>
                                        <Typography variant='h6' color={'#AFAFAF'}>You pledged (SH-Pledge)</Typography>
                                        <Typography variant='h6' color={'var(--colorWhite)'}>100</Typography>
                                    </Box> 
                                    <Divider sx={{borderColor:'#312727'}} />
                                    
                                    <Box paddingY={'1rem'} sx={{display:{md:'flex', sm:'flex', xs:'flex'}, alignItems:'center',justifyContent:'space-between'}}>
                                        <Typography variant='h6' color={'#AFAFAF'}>Pledge share</Typography>
                                        <Typography variant='h6' color={'var(--colorWhite)'}>0.12345%</Typography>
                                    </Box> 
                                    <Divider sx={{borderColor:'#312727'}} />
                                    
                                    <Box paddingY={'1rem'} sx={{display:{md:'flex', sm:'flex', xs:'flex'}, alignItems:'center',justifyContent:'space-between'}}>
                                        <Typography variant='h6' color={'#AFAFAF'}>SolHeist locked</Typography>
                                        <Typography variant='h6' color={'var(--colorWhite)'}> <span style={{color:'#706969', fontSize:'13px'}}>$356.23 ≈</span> 3,800.1234</Typography>
                                    </Box> 
                                    <Divider sx={{borderColor:'#312727'}} />
                                    
                                    <Box paddingY={'1rem'} sx={{display:{md:'flex', sm:'flex', xs:'flex'}, alignItems:'center',justifyContent:'space-between'}}>
                                        <Typography variant='h6' color={'#AFAFAF'}>SolHeist unlocked</Typography>
                                        <Typography variant='h6' color={'var(--colorWhite)'}>200.1234</Typography>
                                    </Box> 
                                    <Divider sx={{borderColor:'#312727'}} />
                                    
                                    <Box paddingY={'1rem'} sx={{display:{md:'flex', sm:'flex', xs:'flex'}, alignItems:'center',justifyContent:'space-between'}}>
                                        <Typography variant='h6' color={'#AFAFAF'}>SolHeist claimed</Typography>
                                        <Typography variant='h6' color={'var(--colorWhite)'}>150.1234</Typography>
                                    </Box> 
                                    <Divider sx={{borderColor:'#312727'}} />
                                    
                                    <Box paddingY={'1rem'} sx={{display:{md:'flex', sm:'flex', xs:'flex'}, alignItems:'center',justifyContent:'space-between'}}>
                                        <Typography variant='h6' color={'#AFAFAF'}>SolHeist unclaimed</Typography>
                                        <Typography variant='h6' color={'var(--colorWhite)'}>50.1234</Typography>
                                    </Box>  

                                    <Box>
                                        <button className='MakepledgeBtn'>Claim SolHeist</button>
                                    </Box> 
                                </Box>
                            </Box>
                            <Box bgcolor={'var(--blackDarkColor)'} marginTop={'3rem'} border={'2px solid #323232'}  sx={{padding:{md:'2rem', sx:'10px', xs:'10px'}}} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                <Box marginBottom={'2rem'}>
                                    <Typography variant='h5' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'} gutterBottom>Upcoming bonus pledges</Typography>
                                </Box>
                                <Box className='TokenAmount'>
                                    <Box marginBottom={'1rem'} sx={{display:{md:'flex', sm:'flex', xs:'flex'}, alignItems:'center',justifyContent:'space-between'}}>
                                        <Box textAlign={'left'}>
                                            <Typography variant='h6' color={'var(--colorWhite)'}>Token</Typography>
                                            <Typography variant='h6' color={'#AFAFAF'}>Sol Professor</Typography>
                                        </Box> 
                                        <Box textAlign={'center'}>
                                            <Typography variant='h6' color={'var(--colorWhite)'}>Amount</Typography>
                                            <Typography variant='h6' color={'#AFAFAF'}>100</Typography>
                                        </Box> 
                                        <Box textAlign={'right'}>
                                            <Typography variant='h6' color={'var(--colorWhite)'}>Status</Typography>
                                            <Typography variant='h6' color={'#AFAFAF'}>Sent</Typography>
                                        </Box> 
                                    </Box>
                                    <Box sx={{display:{md:'flex', sm:'block', xs:'block'}, alignItems:'center',justifyContent:'space-between'}}>
                                        <Box textAlign={'left'}>
                                            <Typography variant='h6' color={'var(--colorWhite)'}>Hash</Typography>
                                            <Typography variant='h6' overflow={'hidden'} color={'#AFAFAF'}>0x1F1e263a37945047aF9C1695f2b9d108d956FBD6</Typography>
                                        </Box> 
                                        <Box>
                                            <button className='blockchainbtn'>View on blockchain</button>
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        <Box>
            <FAQs />
        </Box>
    </Box>
  )
}

export default Pledge