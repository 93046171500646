import React from 'react'
import './Home.css'
import {Box, Container, Grid, Typography} from '@mui/material'
import solanaImage from '../../../assets/img/Solana-logo.png'
import desktopImage from '../../../assets/img/Desktop.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TradingGoesLive from './TradingGoesLive'
import NumbersThatMatter from './NumbersThatMatter'
import UpcomingPledges from './UpcomingPledges'

const Home = () => {
  return (
    <Box>
    <Box bgcolor={'var(--blackDarkColor)'} sx={{padding:{md:'3rem', sm:'2rem', xs:'15px' }}}>
      <Container maxWidth={'lg'}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item lg="6" md="6" sm="12" xs="12">
            <Box sx={{paddingY: {md:'6rem', }}}>
              <Box >
                <Typography variant='h3' fontWeight={'700'} color={'var(--colorWhite)'}>Biggest meme coin project on Soalna blockchain</Typography>
              </Box>
              <Box paddingY={'2rem'}>
                <img src={solanaImage} alt="" className='solanaImage' />
              </Box>
              <Box display='flex' alignItems="center" gap='15px' >
                <button className='PledgeBtn'>
                    <span>Pledge</span>
                    <ArrowForwardIcon color={'var(--colorWhite)'} />
                </button>
                <button className='AdvocateBtn'>
                    <span>Advocate</span>
                    <ArrowForwardIcon color={'var(--colorWhite)'} /> 
              </button>
              </Box>
            </Box>
          </Grid>
          <Grid item lg="6" md="6" sm="12" xs="12">
            <Box>
              <img src={desktopImage} alt="" className='desktopImage' width='100%' />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
    <Box>
      <TradingGoesLive />
    </Box>
    <Box>
      <NumbersThatMatter/>
    </Box>
    <Box>
      <UpcomingPledges  />
    </Box>

    </Box>
  )
}

export default Home