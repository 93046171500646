import { Box, Container, Grid, IconButton, Typography } from '@mui/material';
import React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import filterIcon from '../../../assets/img/filter.png'
import iconImage from '../../../assets/img/solanaIcon.png'
import metaimg1 from '../../../assets/img/metamask-icon-text.png'
import metaimg2 from '../../../assets/img/metamasks.png'
import metaimg3 from '../../../assets/img/metamaskfire.png'




const BuySell = () => {
  return (
    <Box bgcolor={'var(--blackDarkColor)'} paddingY={'4rem'}> 
        <Container maxWidth={'lg'}>
            <Box textAlign={'center'}>
                <Typography variant='h4' fontWeight={'400'} textAlign={'center'} color={'var(--colorWhite)'} gutterBottom>
                    Buy-Sell
                </Typography>
            </Box>
            <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                <Grid item lg='5' md='5' sm='12' xs='12'>
                    <Box className='dashboardContainer' padding={'1rem'} borderRadius={'8px'}>
                        <Box marginBottom={'1rem'}>
                            <Typography variant='h5' textAlign={'center'} fontWeight={'600'} color={'var(--colorWhite)'} gutterBottom >Solbase</Typography>
                        </Box>
                        <Box display='flex' alignItems={'center'} gap='15px' marginBottom={'1.5rem'}>
                            <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                <Box display='flex' alignItems={'center'} gap='10px' padding={'5px 14px'}>
                                    <IconButton>
                                        <RefreshIcon sx={{color:'var(--colorWhite)'}} />
                                    </IconButton>
                                    <Typography variant='h6' color={'var(--colorWhite)'}>0.1%</Typography>
                                </Box>
                            </Box>
                            <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                <Box display='flex' alignItems={'center'} gap='10px' padding={'5px 14px'}>
                                    <IconButton>
                                        <img src={filterIcon} alt='' style={{width:'26px'}} />
                                    </IconButton>
                                    <Typography variant='h6' color={'var(--colorWhite)'}>0.1%</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} marginBottom={'1rem'} padding='1rem' borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                            <Box display='flex' alignItems={'center'} gap={'15px'} justifyContent={'space-between'}>
                                <Box width={'70%'}>
                                    <Box bgcolor={'#2F2D2E'} width={'192px'} paddingX={'10px'} paddingY={'6px'} marginBottom={'10px'} borderRadius={'50px'} display='flex' alignItems={'center'} justifyContent={'space-between'} gap={'10px'}>
                                        <Box display='flex' alignItems={'center'} gap={'10px'}>
                                            <Box padding='5px' borderRadius={'50px'} display={'flex'} alignItems={'center'} justifyContent={'center'} bgcolor={'var(--blackDarkColor)'} width={'40px'} height={'40px'} >
                                                <img src={iconImage} alt='' style={{width:'24px'}}  />
                                            </Box>
                                            <Typography variant='h5' fontWeight={'500'} color={'var(--colorWhite)'}>SOL</Typography>
                                        </Box>
                                        <IconButton>
                                            <KeyboardArrowDownIcon sx={{color:'var(--colorWhite)'}} />
                                        </IconButton>
                                    </Box>
                                    <Box display='flex' alignItems={'center'} gap={'10px'}>
                                        <AccountBalanceWalletIcon sx={{color:'var(--colorWhite)'}}/>
                                        <Typography variant='h5' fontWeight={'500'} color={'var(--colorWhite)'}>0.00000 SOL</Typography>
                                    </Box>
                                </Box>
                                <Box width={'30%'}>
                                    <input placeholder='0.00' className='SellInput' />
                                </Box>
                            </Box>
                        </Box>
                        <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} padding='1rem' borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                            <Box display='flex' alignItems={'center'} gap={'15px'} justifyContent={'space-between'}>
                                <Box width={'70%'}>
                                    <Box bgcolor={'#2F2D2E'} width={'192px'} paddingX={'10px'} paddingY={'6px'} marginBottom={'10px'} borderRadius={'50px'} display='flex' alignItems={'center'} justifyContent={'space-between'} gap={'10px'}>
                                        <Box display='flex' alignItems={'center'} gap={'10px'}>
                                            <Box padding='5px' borderRadius={'50px'} display={'flex'} alignItems={'center'} justifyContent={'center'} bgcolor={'var(--blackDarkColor)'} width={'40px'} height={'40px'} >
                                                <img src={iconImage} alt='' style={{width:'24px'}}  />
                                            </Box>
                                            <Typography variant='h5' fontWeight={'500'} color={'var(--colorWhite)'}>Heist</Typography>
                                        </Box>
                                        <IconButton>
                                            <KeyboardArrowDownIcon sx={{color:'var(--colorWhite)'}} />
                                        </IconButton>
                                    </Box>
                                    <Box display='flex' alignItems={'center'} gap={'10px'}>
                                        <AccountBalanceWalletIcon sx={{color:'var(--colorWhite)'}}/>
                                        <Typography variant='h5' fontWeight={'500'} color={'var(--colorWhite)'}>0.00000 SolHeist</Typography>
                                    </Box>
                                </Box>
                                <Box width={'30%'}>
                                    <input placeholder='0.00' className='SellInput' />
                                </Box>
                            </Box>
                        </Box>
                        <Box marginY={'2rem'}>
                            <button className='MakepledgeBtn' style={{width:'200px', margin:'auto'}}>
                                Swap
                            </button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item lg='5' md='5' sm='12' xs='12'>
                    <Box className='dashboardContainer' padding={'1rem'} borderRadius={'8px'}>
                        <Box marginBottom={'1rem'}>
                            <Typography variant='h5' textAlign={'center'} fontWeight={'600'} color={'var(--colorWhite)'} gutterBottom >Raydium</Typography>
                        </Box>
                        <Box display='flex' alignItems={'center'} gap='15px' marginBottom={'1.5rem'}>
                            <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                <Box display='flex' alignItems={'center'} gap='10px' padding={'5px 14px'}>
                                    <IconButton>
                                        <RefreshIcon sx={{color:'var(--colorWhite)'}} />
                                    </IconButton>
                                    <Typography variant='h6' color={'var(--colorWhite)'}>0.1%</Typography>
                                </Box>
                            </Box>
                            <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                                <Box display='flex' alignItems={'center'} gap='10px' padding={'5px 14px'}>
                                    <IconButton>
                                        <img src={filterIcon} alt='' style={{width:'26px'}} />
                                    </IconButton>
                                    <Typography variant='h6' color={'var(--colorWhite)'}>0.1%</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} marginBottom={'1rem'} padding='1rem' borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                            <Box display='flex' alignItems={'center'} gap={'15px'} justifyContent={'space-between'}>
                                <Box width={'70%'}>
                                    <Box bgcolor={'#2F2D2E'} width={'192px'} paddingX={'10px'} paddingY={'6px'} marginBottom={'10px'} borderRadius={'50px'} display='flex' alignItems={'center'} justifyContent={'space-between'} gap={'10px'}>
                                        <Box display='flex' alignItems={'center'} gap={'10px'}>
                                            <Box padding='5px' borderRadius={'50px'} display={'flex'} alignItems={'center'} justifyContent={'center'} bgcolor={'var(--blackDarkColor)'} width={'40px'} height={'40px'} >
                                                <img src={iconImage} alt='' style={{width:'24px'}}  />
                                            </Box>
                                            <Typography variant='h5' fontWeight={'500'} color={'var(--colorWhite)'}>SOL</Typography>
                                        </Box>
                                        <IconButton>
                                            <KeyboardArrowDownIcon sx={{color:'var(--colorWhite)'}} />
                                        </IconButton>
                                    </Box>
                                    <Box display='flex' alignItems={'center'} gap={'10px'}>
                                        <AccountBalanceWalletIcon sx={{color:'var(--colorWhite)'}}/>
                                        <Typography variant='h5' fontWeight={'500'} color={'var(--colorWhite)'}>0.00000 SOL</Typography>
                                    </Box>
                                </Box>
                                <Box width={'30%'}>
                                    <input placeholder='0.00' className='SellInput' />
                                </Box>
                            </Box>
                        </Box>
                        <Box bgcolor={'var(--blackDarkColor)'} border={'2px solid #323232'} padding='1rem' borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                            <Box display='flex' alignItems={'center'} gap={'15px'} justifyContent={'space-between'}>
                                <Box width={'70%'}>
                                    <Box bgcolor={'#2F2D2E'} width={'192px'} paddingX={'10px'} paddingY={'6px'} marginBottom={'10px'} borderRadius={'50px'} display='flex' alignItems={'center'} justifyContent={'space-between'} gap={'10px'}>
                                        <Box display='flex' alignItems={'center'} gap={'10px'}>
                                            <Box padding='5px' borderRadius={'50px'} display={'flex'} alignItems={'center'} justifyContent={'center'} bgcolor={'var(--blackDarkColor)'} width={'40px'} height={'40px'} >
                                                <img src={iconImage} alt='' style={{width:'24px'}}  />
                                            </Box>
                                            <Typography variant='h5' fontWeight={'500'} color={'var(--colorWhite)'}>Heist</Typography>
                                        </Box>
                                        <IconButton>
                                            <KeyboardArrowDownIcon sx={{color:'var(--colorWhite)'}} />
                                        </IconButton>
                                    </Box>
                                    <Box display='flex' alignItems={'center'} gap={'10px'}>
                                        <AccountBalanceWalletIcon sx={{color:'var(--colorWhite)'}}/>
                                        <Typography variant='h5' fontWeight={'500'} color={'var(--colorWhite)'}>0.00000 SolHeist</Typography>
                                    </Box>
                                </Box>
                                <Box width={'30%'}>
                                    <input placeholder='0.00' className='SellInput' />
                                </Box>
                            </Box>
                        </Box>
                        <Box marginY={'2rem'}>
                            <button className='MakepledgeBtn' style={{width:'200px', margin:'auto'}}>
                                Swap
                            </button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item md='6' sm="12" xs='12' margin='auto'>
                    <Box marginY='2rem'>
                        <Box>
                            <Typography variant='h5' textAlign='center' color={'var(--colorWhite)'}>- OR -</Typography>
                        </Box>
                        <Box marginY='2rem' sx={{display:{md:'flex', sm:'flex', xs:'block'}, alignItems:'center', gap:'10px', flexWrap:'wrap'}}> 
                            <Box sx={{width:{md:'48%', sm:'49%', xs:'100%'}}}> 
                                <button className='SolHeistoutline' style={{borderRadius:'50px', margin:'5px 0px'}}>
                                    BUY ON JUP.AG 
                                </button>
                            </Box>
                            <Box sx={{width:{md:'48%', sm:'49%', xs:'100%'}}}>
                                <button className='SolHeistoutline' style={{borderRadius:'50px', margin:'5px 0px'}}>
                                    BUY ON RAYDIUM.IO 
                                </button>
                            </Box>
                            <Box sx={{width:{md:'48%', sm:'49%', xs:'100%'}}}>
                                <button className='SolHeistoutline' style={{borderRadius:'50px', margin:'5px 0px'}}>
                                    VIEW ON BIRDEYE.SO  
                                </button>
                            </Box>
                            <Box sx={{width:{md:'48%', sm:'49%', xs:'100%'}}}>
                                <button className='SolHeistoutline' style={{borderRadius:'50px', margin:'5px 0px'}}>
                                    VIEW ON DEXSCREENER.COM
                                </button>
                            </Box>
                        </Box>
                        <Box bgcolor={'var(--blackDarkColor)'} padding='1rem' border={'2px solid #323232'} borderRadius={'8px'} boxShadow={'0px 0px 7px #000'}>
                            <Typography variant='h5' textAlign='center' color={'var(--colorWhite)'} gutterBottom>
                                Install wallet
                            </Typography>
                            <Box display='flex' alignItems={'center'} justifyContent={'center'} gap={'40px'} marginTop={'2rem'}>
                                <Box>
                                    <img src={metaimg1} alt='' style={{width:'75px'}} />
                                </Box> 
                                <Box>
                                    <img src={metaimg2} alt='' style={{width:'75px'}} />
                                </Box> 
                                <Box>
                                    <img src={metaimg3} alt='' style={{width:'75px'}} />
                                </Box> 
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container> 
    </Box>
  )
}

export default BuySell;