import { Box } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const NavBar = () => {

    const [activeLink, setActiveLink] = useState('home'); // State to keep track of active link

    // Function to handle click event on navbar links
    const handleNavLinkClick = (link) => {
      setActiveLink(link); // Update active link state
    };

  return (
    <Box>
        <ul className="UnListBox">
            <li>
                <Link className={activeLink === 'home' ? 'active' : ''} onClick={() => handleNavLinkClick('home')} to='/'>Home</Link>
            </li>
            <li>
                <Link className={activeLink === 'buy-sell' ? 'active' : ''} onClick={() => handleNavLinkClick('buy-sell')} to='/buy-sell'>Buy-Sell</Link>
            </li>
            <li>
                <Link className={activeLink === 'tokenomics' ? 'active' : ''} onClick={() => handleNavLinkClick('tokenomics')} to='/tokenomics'>Tokenomics</Link>
            </li>
            <li>
                <Link className={activeLink === 'pledge' ? 'active' : ''} onClick={() => handleNavLinkClick('pledge')} to='/pledge'>Pledge</Link>
            </li>
            <li>
                <Link className={activeLink === 'advocate-dashboard' ? 'active' : ''} onClick={() => handleNavLinkClick('advocate-dashboard')} to='/advocate-dashboard'>Advocate</Link>
            </li>
            <li>
                <Link className={activeLink === '' ? '' : ''} onClick={() => handleNavLinkClick('')} to='/'>Upcoming</Link>
            </li>
        </ul>
    </Box>
  )
}

export default NavBar