import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion"; 
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

 


const FAQs = () => {
    return (
        <Box marginTop={"2rem"}>
            <Container maxWidth="lg">
                <Box>
                    <Accordion sx={{backgroundColor:'#2F2D2E', color:'var(--colorWhite)', marginBottom:'6px', borderRadius:'8px !important', boxShadow:'none'}} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color:'var(--colorWhite)'}} />} aria-controls="panel1-content" id="panel1-header">
                           <Typography variant="h6">Who should use the app?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box>
                                <Typography>The Service provided by the Company involves custodial key management for crypto wallets. This includes but is not limited to, storing, securing, and managing cryptographic keys on behalf of the User for accessing and managing cryptocurrencies.</Typography>  
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{backgroundColor:'#2F2D2E', color:'var(--colorWhite)', marginBottom:'6px', borderRadius:'8px !important', boxShadow:'none'}} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color:'var(--colorWhite)'}} />} aria-controls="panel1-content" id="panel1-header">
                           <Typography variant="h6">What is included with my subscription?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box>
                                <Typography>The Service provided by the Company involves custodial key management for crypto wallets. This includes but is not limited to, storing, securing, and managing cryptographic keys on behalf of the User for accessing and managing cryptocurrencies.</Typography>  
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{backgroundColor:'#2F2D2E', color:'var(--colorWhite)', marginBottom:'6px', borderRadius:'8px !important', boxShadow:'none'}} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color:'var(--colorWhite)'}} />} aria-controls="panel1-content" id="panel1-header">
                           <Typography variant="h6">How do I get paid?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box>
                                <Typography>The Service provided by the Company involves custodial key management for crypto wallets. This includes but is not limited to, storing, securing, and managing cryptographic keys on behalf of the User for accessing and managing cryptocurrencies.</Typography>  
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{backgroundColor:'#2F2D2E', color:'var(--colorWhite)', marginBottom:'6px', borderRadius:'8px !important', boxShadow:'none'}} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color:'var(--colorWhite)'}} />} aria-controls="panel1-content" id="panel1-header">
                           <Typography variant="h6">Is my personal information safe?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box>
                                <Typography>The Service provided by the Company involves custodial key management for crypto wallets. This includes but is not limited to, storing, securing, and managing cryptographic keys on behalf of the User for accessing and managing cryptocurrencies.</Typography>  
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{backgroundColor:'#2F2D2E', color:'var(--colorWhite)', marginBottom:'6px', borderRadius:'8px !important', boxShadow:'none'}} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color:'var(--colorWhite)'}} />} aria-controls="panel1-content" id="panel1-header">
                           <Typography variant="h6">How can we get in touch?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box>
                                <Typography>The Service provided by the Company involves custodial key management for crypto wallets. This includes but is not limited to, storing, securing, and managing cryptographic keys on behalf of the User for accessing and managing cryptocurrencies.</Typography>  
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                     
                </Box>
            </Container>
        </Box>
    );
};

export default FAQs;
