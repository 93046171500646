import { Box, Container, useMediaQuery, useTheme} from '@mui/material'
import React from 'react'
import './header.css'
import logoSol from '../../../assets/logo/solheist-logo.png'
import { Link } from 'react-router-dom'
import Menus from './Menus' 
import NavBar from './NavBar'

const HeaderBar = () => {

    // const [value, setValue] = useState();
    const theme = useTheme();
    console.log(theme);

    const isMatch = useMediaQuery(theme.breakpoints.down('md'));


  return (
    <Box bgcolor={'var(--blackDarkColor)'} paddingY={'10px'}>
        <Container maxWidth={'lg'}> 
                <Box className="headerMenuBox">
                    <Box>
                        <Link to='/'>
                            <img src={logoSol} style={{width:'180px'}} alt='' />
                        </Link>
                    </Box>
                     
                        {
                            isMatch ? (
                                <>
                                    <Menus />
                                </>
                            ) : (
                            <>
                                <NavBar />
                            </>
                        )
                    }
                     
                    <Box>
                        <button className='connectButtons'>
                            Connect
                        </button>
                    </Box>
                </Box> 
        </Container>
    </Box>
  )
}

export default HeaderBar