import React from 'react'
import {BrowserRouter, Routes, Route,} from "react-router-dom";
// import Announcement from './components/announcement/Announcement'
import HeaderBar from './components/pages/headers/HeaderBar';
import Home from './components/pages/mainpage/Home';
import Footer from './components/pages/footer/Footer';
import Pledge from './components/pages/presela/Pledge';
import Tokenomics from './components/pages/tokenomics/Tokenomics';
import AdvocateDashboard from './components/pages/Advocatedashboard/AdvocateDashboard';
import BuySell from './components/pages/buysell/BuySell';

const App = () => {
  return (
    <BrowserRouter>
      {/*<Announcement />*/}
        <HeaderBar /> 
          <Routes>
            <Route path="/" element={<Home />}>
              <Route index element={<Home />} />
            </Route> 
            <Route path='/buy-sell' element={<BuySell />} />
            <Route path='/tokenomics' element={<Tokenomics />} />
            <Route path='/pledge' element={<Pledge />} />
            <Route path='/advocate-dashboard' element={<AdvocateDashboard />} />
          </Routes>
          <Footer />
      </BrowserRouter> 
  )
}

export default App