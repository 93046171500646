import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import coinimg from '../../../assets/img/picture.png'
import './Home.css'
import { Link } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const coinData = [
    {
        imgae: coinimg,
        title:'Coin 1',
        cointDis:'Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.',
        morebtn:'More Info',
        time:'14 days : 18 hours to go'
    },
    {
        imgae: coinimg,
        title:'Coin 2',
        cointDis:'Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.',
        morebtn:'More Info',
        time:'29 days : 15 hours to go'
    },
    {
        imgae: coinimg,
        title:'Coin 3',
        cointDis:'Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.',
        morebtn:'More Info',
        time:'59 days : 18 hours to go'
    },
    {
        imgae: coinimg,
        title:'Coin 4',
        cointDis:'Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.',
        morebtn:'More Info',
        time:'79 days : 18 hours to go'
    },
]
const UpcomingPledges = () => {
  return (
    <Box bgcolor={'var(--blackDarkColor)'} paddingY={'4rem'}>
        <Container maxWidth="lg">
            <Box textAlign='center' marginBottom={'3rem'}> 
                <Typography variant='h3' textAlign={'center'} color={'var(--colorWhite)'} fontWeight={'600'} gutterBottom>
                    Upcoming pledges
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {coinData.map((item, index) => {
                    return(
                        <Grid key={index} item md='3' sm='6' xs='12' >
                            <Box borderRadius={'8px'} bgcolor={'#2F2D2E'} overflow={'hidden'}>
                                <Box>
                                    <img src={item.imgae} alt='' className='coinImages' />
                                </Box>
                                <Box padding={'12px'}>
                                    <Typography variant='h6' fontWeight={'700'} color={'var(--colorWhite)'}>{item.title}</Typography>
                                    <Typography variant='body' fontWeight={'400'} color={'var(--colorWhite)'}>{item.cointDis}</Typography>
                                </Box>
                                <Box padding={'12px'}>
                                    <Link to='/' className='morebtnLink'>
                                        <span>{item.morebtn}</span>
                                        <ArrowForwardIcon />
                                    </Link>
                                </Box>
                                <Box padding={'10px'} bgcolor={'var(--bolorYellowHover)'}>
                                    <Typography variant='h6'fontWeight={'700'} textAlign={'center'}>{item.time}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Container>
    </Box>
  )
}

export default UpcomingPledges