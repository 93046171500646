import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import LanguageIcon from "@mui/icons-material/Language";
import ShareIcon from "@mui/icons-material/Share";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FAQs from "../faqs/FAQs";

const AdvocateDashboard = () => {
    return (
        <Box bgcolor={"var(--blackDarkColor)"} paddingY={"4rem"}>
            <Box>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={2}>
                        <Grid item lg="10" md="10" sm="12" xs="12" margin={"auto"}>
                            <Box className="dashboardContainer" sx={{ paddingX: { md: "4rem", sm: "10px", xs: "10px" }, paddingY: { md: "3rem", sm: "20px", xs: "16px" } }} borderRadius={"8px"}>
                                <Box>
                                    <Typography variant="h4" fontWeight={"400"} textAlign={"center"} color={"var(--colorWhite)"} gutterBottom>
                                        Advocate Dashboard
                                    </Typography>

                                    <Box bgcolor={"var(--blackDarkColor)"} border={"2px solid #323232"} marginTop={"1rem"} sx={{ padding: { md: "2rem", sm: "10px", xs: "10px" } }} borderRadius={"8px"} boxShadow={"0px 0px 7px #000"}>
                                        <Box marginBottom={"2rem"}>
                                            <Typography variant="h5" fontWeight={"400"} textAlign={"center"} color={"var(--colorWhite)"} gutterBottom>
                                                Your advocate address
                                            </Typography>
                                            <Typography variant="h5" fontWeight={"400"} textAlign={"center"} color={"#FF929D"} overflow={"hidden"} gutterBottom>
                                                0x1F1e263a37945047aF9C1695f2b9d108d956FBD6
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: { md: "flex", sm: "flex", xs: "block" }, alignItems: "center", gap: "15px", justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <Box sx={{ width: { md: "48%", sm: "48%", xs: "100%" } }}>
                                                <button className="SolHeistoutline">
                                                    <ContentCopyIcon /> Copy Code
                                                </button>
                                            </Box>
                                            <Box sx={{ width: { md: "48%", sm: "48%", xs: "100%" } }}>
                                                <button className="SolHeistoutline">
                                                    <QrCodeScannerIcon /> Open QR Code
                                                </button>
                                            </Box>
                                            <Box sx={{ width: { md: "48%", sm: "48%", xs: "100%" } }}>
                                                <button className="SolHeistoutline">
                                                    <LanguageIcon /> Copy URL
                                                </button>
                                            </Box>
                                            <Box sx={{ width: { md: "48%", sm: "48%", xs: "100%" } }}>
                                                <button className="SolHeistoutline">
                                                    <ShareIcon /> Share URL
                                                </button>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box borderRadius="8px" padding="15px" bgcolor={"#2F2D2E"} marginY={"2rem"}>
                                        <Typography color={"#AFAFAF"}>Become a SolHeist advocate and help raise awareness about SolHeist. Advocates have the opportunity to earn fantastic bonuses in Solana and SH-Pledge tokens.</Typography>
                                    </Box>
                                </Box>
                                <Box marginBottom={"2rem"} sx={{ display: { md: "flex", sm: "flex", xs: "flex" }, alignItems: "center", justifyContent: "space-between", gap: "15px" }}>
                                    <Box width={"100%"}>
                                        <button className="SOLearned">
                                            <Typography textAlign={"center"} variant="h4" color={"var(--colorWhite)"}>
                                                1794.54
                                            </Typography>
                                            <Typography textAlign={"center"} variant="body" color={"var(--colorWhite)"}>
                                                SOL earned
                                            </Typography>
                                        </button>
                                        <Typography display={"flex"} justifyContent={"center"} color={"var(--colorWhite)"} alignItems={"center"} gap="10px">
                                            View transactions <ArrowForwardIcon />{" "}
                                        </Typography>
                                    </Box>
                                    <Box width={"100%"}>
                                        <button className="SOLearned">
                                            <Typography textAlign={"center"} variant="h4" color={"var(--colorWhite)"}>
                                                1794.54
                                            </Typography>
                                            <Typography textAlign={"center"} variant="body" color={"var(--colorWhite)"}>
                                                SOL earned
                                            </Typography>
                                        </button>
                                        <Typography display={"flex"} justifyContent={"center"} color={"var(--colorWhite)"} alignItems={"center"} gap="10px">
                                            View transactions <ArrowForwardIcon />{" "}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box bgcolor={"var(--blackDarkColor)"} border={"2px solid #323232"} marginBottom={"2rem"} borderRadius={"8px"} boxShadow={"0px 0px 7px #000"}>
                                    <Box bgcolor={"#2F2D2E"} padding={"15px"}>
                                        <Typography variant="h5" fontWeight={"400"} textAlign={"center"} color={"var(--colorWhite)"}>
                                            Invite 5 and earn 10% SOL
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Target
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                5
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Accepted
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                2 Users
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Bonus earned
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                0.3156 SOL
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Amount
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                4.1456 SOL
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box borderRadius="8px" padding="15px" bgcolor={"#2F2D2E"} marginY={"2rem"}>
                                    <Typography color={"#AFAFAF"}>Get an additional 10% SOL bonus for the first 5 users you refer to SolHeist.</Typography>
                                </Box>
                                <Box bgcolor={"var(--blackDarkColor)"} border={"2px solid #323232"} marginBottom={"2rem"} borderRadius={"8px"} boxShadow={"0px 0px 7px #000"}>
                                    <Box bgcolor={"#2F2D2E"} padding={"15px"}>
                                        <Typography variant="h5" fontWeight={"400"} textAlign={"center"} color={"var(--colorWhite)"}>
                                            Invite 5 and earn 10% SOL
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Target
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                5
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Accepted
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                2 Users
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Bonus earned
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                0.3156 SOL
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Amount
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                4.1456 SOL
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography variant="h5" fontWeight={"400"} textAlign={"center"} color={"var(--colorWhite)"} gutterBottom>
                                        Your invites
                                    </Typography>
                                </Box>
                                <Box bgcolor={"var(--blackDarkColor)"} border={"2px solid #323232"} marginBottom={"2rem"} borderRadius={"8px"} boxShadow={"0px 0px 7px #000"}>
                                    <Box bgcolor={"#2F2D2E"} padding={"15px"} position="relative">
                                        <button className="Levelbtn">Level 1</button>
                                        <Typography variant="h5" fontWeight={"400"} textAlign={"center"} color={"var(--colorWhite)"}>
                                            Direct pledges
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Users
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                5
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Pledged
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                3,000
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Bonus
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                20%
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Amount
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                1,500
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                SOL earned (10%)
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                0.9345
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Pledge earned (10%)
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                150
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box bgcolor={"var(--blackDarkColor)"} border={"2px solid #323232"} marginBottom={"2rem"} borderRadius={"8px"} boxShadow={"0px 0px 7px #000"}>
                                    <Box bgcolor={"#2F2D2E"} padding={"15px"} position="relative">
                                        <button className="Levelbtn">Level 2</button>
                                        <Typography variant="h5" fontWeight={"400"} textAlign={"center"} color={"var(--colorWhite)"}>
                                            Second level pledges
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Users
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                5
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Pledged
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                3,000
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Bonus
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                20%
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Amount
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                1,500
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                SOL earned (10%)
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                0.9345
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Pledge earned (10%)
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                150
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box bgcolor={"var(--blackDarkColor)"} border={"2px solid #323232"} borderRadius={"8px"} boxShadow={"0px 0px 7px #000"}>
                                    <Box bgcolor={"#2F2D2E"} padding={"15px"} position="relative">
                                        <button className="Levelbtn">Level 3</button>
                                        <Typography variant="h5" fontWeight={"400"} textAlign={"center"} color={"var(--colorWhite)"}>
                                            Third level pledges
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Users
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                5
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Pledged
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                3,000
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Bonus
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                20%
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Amount
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                1,500
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                SOL earned (10%)
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                0.9345
                                            </Typography>
                                        </Box>
                                        <Box padding={"14px"} className="InviteBox">
                                            <Typography variant="body" color={"#AFAFAF"}>
                                                Pledge earned (10%)
                                            </Typography>
                                            <Typography variant="body" fontWeight={"600"} color={"var(--colorWhite)"}>
                                                150
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box>
                <FAQs />
            </Box>
        </Box>
    );
};

export default AdvocateDashboard;
